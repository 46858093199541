.container {
  display: flex;
  justify-content: center;
  padding: 2em;
}

.form-container {
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  padding: 2em;  
  
}

.ant-table-cell {
  background-color: transparent !important;
}
.ant-table {
  background-color: transparent !important;
}